import { useProductsCount } from '@bluheadless/ui-logic/src/hooks/product'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useProductContext } from '@bluheadless/ui-logic/src/providers/product'
import { filterProductsByAttribute } from '@bluheadless/ui/src/organisms/product/sidebar/related-attributes'
import { useIntl } from 'react-intl'

const TileMiddleware = ({ Tile, ...props }) => {
	const { variant, colorsProps } = props
	const {
		catalog: {
			products: { relatedAttributes = [] },
		},
	} = useConfig()

	const { formatMessage } = useIntl()

	const productContextValue = useProductContext() ?? []

	const [{ product: { attributes: parentAttributes, id } = {} }] = productContextValue
	const productsToSelect = filterProductsByAttribute(relatedAttributes, parentAttributes)
	const { count, loading: loadingProducts } = useProductsCount({
		paused: !relatedAttributes?.length > 0 || !productsToSelect.length > 0,
		where: {
			and: [{ visible: 1 }, { id: { neq: id } }, ...productsToSelect],
		},
	})

	if (variant === 'base' && !loadingProducts && count > 0) {
		if (!colorsProps || !Object.keys(colorsProps).length) {
			props.colorsProps = {
				position: 'inner',
				expanded: false,
				label: formatMessage({ id: 'tile_product_colors' }, { count: count + 1 }),
			}
		}
	}
	return <Tile {...props} />
}

export default TileMiddleware
