import dynamic from 'next/dynamic'
import SkeletonCard from '../../skeleton/card/skeleton-card'

// DON'T USE VARIABLE FOR DYNAMIC OPTIONS, CAUSE Did not expect server HTML to contain a <div> in <div>
/* const dynamicOptions = {
	loading: SkeletonCard,
} */

const TileTooltip = dynamic(() => import('@/components/ui/molecules/tile/tooltip'), {
	loading: SkeletonCard,
})

const TileSlide = dynamic(() => import('@/components/ui/molecules/tile/slide'), {
	loading: SkeletonCard,
})

export const customVariants = {
	tooltip: TileTooltip,
	slide: TileSlide,
}
